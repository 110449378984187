:root {
  --color: #6168ff;
  --footer: #00102a;
}

#style-switcher h2 a {
  background: var(--color) none repeat scroll 0 0 !important;
}

#home .swiper-button-prev::after,
#home .swiper-button-next:after,
.single_choose .material-icons,
.section-title h2,
.service .icon,
.footer a:hover,
.footer a:focus,
.footer .foot_social_link li a,
.slicknav_nav_icon,
#navigation.navbar-fixed #main-menu ul li a:hover,
#navigation.navbar-fixed #main-menu ul li a:focus,
#main-menu ul li a:hover,
#main-menu ul li a:focus,
.tour_slider .swiper-button-prev::after,
.tour_slider .swiper-button-next::after,
.widget_categories i {
  color: var(--color);
}

#navigation #main-menu ul li ul,
#navigation #main-menu ul li ul li ul {
  border-top-color: var(--color);
}

.choose_img .pbtn,
#top-package .owl-nav button .material-icons,
.pack_price,
.topcontrol,
#navigation #main-menu ul li ul li a:hover,
#navigation.navbar-fixed #main-menu ul li ul li a:hover,
#navigation.navbar-fixed #main-menu ul li ul li a:focus,
.meta_cat a,
.post_tags a,
.tour_duration,
#top-package .swiper-button-prev,
#top-package .swiper-button-next {
  background-color: var(--color);
  color: #fff;
}

.btn-home-bg,
.btn-contact-bg,
#submitButton.btn-contact-bg,
.pagination-nav a:hover,
.pagination-nav a:focus,
.pagination-nav a.active,
.widget.widget_categories ul>li:hover,
.widget_tag_cloud a:hover,
.widget_tag_cloud a:focus,
#home .swiper-button-prev:hover,
#home .swiper-button-next:hover {
  background-color: var(--color);
  border-color: var(--color) !important;
}

.partner-logo,
#testimonial-slider .test-img::after,
.search_form button {
  background-color: var(--color);
}

.contact .form-control:focus {
  border-color: var(--color);
}

.single-gallery::before {
  background-color: var(--color);
}

#home .swiper-button-prev:hover::after,
#home .swiper-button-prev:focus:after,
#home .swiper-button-next:hover:after,
#home .swiper-button-nextt:focus:after {
  transition: all 0.5s ease;
  color: #fff;
}

.footer {}