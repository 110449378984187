.box_body {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.501);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  transition: all 0.5s ease;
}

.box_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.arrow {
  flex-basis: 10%;
}
.arrow img {
  width: 40px;
  cursor: pointer;
}

.right_arrow {
  display: flex;
  justify-content: flex-end;
  order: 3;
}
.img_content {
  flex-basis: 80%;
  order: 2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img_content .img_div {
  height: 70%;
  width: 100%;
  position: relative;
}
.img_div img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.close_btn {
  position: absolute;
  top: -2rem;
  right: 0;
  border: none;
  color: #fff;
  background: none;
  outline: none;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
}
.length {
  position: absolute;
  bottom: -3rem;
  right: 0;
  color: #ddd;
}
.responsive_arrow {
  display: none;
}

@media screen and (max-width: 992px) {
  .img_content .img_div {
    height: 55%;
    width: 90%;
  }
  .img_content {
    order: 1;
    flex-basis: 100%;
    height: 95vh;
  }
  .arrow {
    display: none !important;
  }
  .res_arrow img {
    width: 40px;
    cursor: pointer;
  }
  .responsive_arrow {
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -60%);
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 3rem;
  }
}
