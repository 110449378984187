/*
Author       : Masum Billah.
Template Name: ward - Tour & Travel Agency Template
Version      : 1.0
*/
/*=============================================================
    CSS INDEX
    =============================
    01. GENERAL STYLE (body, link color, section-title, preloader btn, overlay, section-padding etc)
    02. START BOOTSTRAP NAVIGATION OVERRIDES
    03. START HOME DESIGN
    04. START SERVICE DESIGN
    05. START WHY CHOOSE DESIGN
    06. START SPECIAL PACKAGE DESIGN
    07. START TOP DEALS DESIGN
    08. START DEALS & DISCOUNT DESIGN
    09. START GALLERY DESIGN
    10. START BLOG DESIGN
    11. START TESTIMONIAL DESIGN
    12. START COMPANY PARTNER LOGO
    13. START CONTACT DESIGN
    14. START FOOTER DESIGN
  =============================================================*/
/*
* ----------------------------------------------------------------------------------------
* 01.GENERAL STYLE
* ----------------------------------------------------------------------------------------
*/
body {
  color: #666;
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  line-height: 1.8;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
}

body.active {
  overflow: hidden;
}

html,
body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  font-family: "Nunito", sans-serif;
  color: #333;
  font-weight: 700 !important;
  line-height: normal;
}

a {
  text-decoration: none !important;
  transition: all 0.2s ease 0s;
  cursor: pointer;
}

body a,
a:hover,
body a:focus {
  color: #727272;
  text-decoration: none;
}

p {
  margin-bottom: 15px;
}

.row>* {
  padding-left: 15px;
  padding-right: 15px;
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  margin-bottom: 0 !important;
}

fieldset {
  border: 0 none;
  margin: 0 auto;
  padding: 0;
}

/*START PRELOADER DESIGN*/
.preloader {
  background: #6168ff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}

.lds-roller {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.no-padding {
  padding: 0;
}

/*END PRELOADER DESIGN*/
.section-padding {
  padding: 130px 0;
}

/*START SECTION TITLE DESIGN*/
.section-title {
  margin-bottom: 60px;
}

.section-title h2 {
  margin-top: 0;
  position: relative;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.3;
  margin-bottom: 0px;
  color: #6168ff;
}

.section-title {
  position: relative;
}

span.bg_title_text {
  line-height: 1;
  top: -10px;
  position: absolute;
  text-transform: uppercase;
  z-index: -1;
  font-weight: 900 !important;
  font-size: 73px;
  color: #efefef;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.section-title p {
  font-size: 27px;
  margin-bottom: 0;
  line-height: 38px;
  color: #333;
}

/*END SECTION TITLE DESIGN*/
/*START SCROLL TO TOP*/
.topcontrol {
  background: #6168ff none repeat scroll 0 0;
  border-radius: 50%;
  bottom: 5px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.06);
  color: #fff;
  cursor: pointer;
  font-size: 19px;
  height: 45px;
  line-height: 45px;
  opacity: 1;
  position: fixed;
  right: 5px;
  text-align: center;
  transition: all 0.2s ease 0s;
  width: 45px;
  z-index: 999;
  opacity: 0;
}

.topcontrol.showRocket {
  opacity: 1;
}

.topcontrol:hover {
  background: #211e3b;
  color: #fff;
}

/*END SCROLL TO TOP*/
/*
* ----------------------------------------------------------------------------------------
* 01.END GENERAL STYLE
* ----------------------------------------------------------------------------------------
*/

/*
* ----------------------------------------------------------------------------------------
* 02.START BOOTSTRAP NAVIGATION OVERRIDES
* ----------------------------------------------------------------------------------------
*/

.site-logo {
  width: 120px;
}

.bg-faded {
  background-color: transparent;
  transition: all 0.5s ease 0s;
}

.navbar-fixed {
  z-index: 999;
  position: fixed;
  opacity: 0.98;
  width: 100%;
  padding: 10px 0;
  top: 0;
  -webkit-animation: fadeInDown 800ms;
  animation: fadeInDown 800ms;
  -webkit-backface-visibility: hidden;
  border-radius: 5px;
  background: #6168ff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.07);
}

#navigation {
  height: 90px;
  padding: 10px 0 0px;
  border-bottom: 1px solid rgb(247, 243, 243, 0.4);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#navigation.navbar-fixed {
  padding-top: 8px;
  background-color: #fff;
  border-color: #fff;
}

.header_right {
  display: flex;
  flex-basis: auto;
}

#main-menu ul {
  list-style-type: none;
}

#main-menu ul li {
  display: inline-block;
  margin-left: 40px;
  position: relative;
}

#main-menu ul li a {
  color: #333;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Nunito", sans-serif;
  transition: 0.5s;
  font-size: 17px;
  padding: 24px 0 26px;
  display: block;
}

#navigation.navbar-fixed #main-menu ul li a {
  color: #333;
  padding: 22px 0 22px;
}

#navigation.navbar-fixed #main-menu ul li a:hover,
#navigation.navbar-fixed #main-menu ul li a:focus,
#main-menu ul li a:hover,
#main-menu ul li a:focus {
  color: #6168ff;
}

#main-menu ul li>a:hover,
#main-menu ul li>a:focus {
  color: #6168ff;
}

#navigation #main-menu ul li a:hover,
#navigation #main-menu ul li a:focus,
#navigation.navbar-fixed #main-menu ul li a:hover,
#navigation.navbar-fixed #main-menu ul li a:focus {}

.slicknav_nav_icon {
  color: #6168ff;
  font-size: 24px;
}

#mobile_menu {
  display: none;
}

/*-------------------------------
# dropdwon menu 
--------------------------------*/

#navigation #main-menu ul li ul,
#navigation #main-menu ul li ul li ul {
  background: #fff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  left: -55px;
  list-style: outside none none;
  margin: 0;
  opacity: 0;
  padding: 10px;
  position: absolute;
  text-align: left;
  top: 100%;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 200px;
  z-index: 999;
  border-radius: 4px;
  border-top: 2px solid #6168ff;
}

#navigation #main-menu ul li ul li {
  position: relative;
}

#navigation #main-menu ul li ul li ul {
  top: 0;
  right: auto;
  left: 205px;
}

#navigation.navbar-fixed #main-menu ul li li a {
  color: #333;
}

#navigation #main-menu ul li:hover ul,
#navigation #main-menu ul li ul li:hover>ul {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
}

#navigation #main-menu ul li ul li {
  display: block;
  margin: 0;
  padding: 0;
}

#navigation.navbar-fixed #main-menu ul li ul li a,
#navigation #main-menu ul li ul li a {
  color: #333;
  display: block;
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  padding: 5px 12px;
  position: relative;
  transition: all 0.3s ease 0s !important;
  visibility: inherit !important;
  opacity: inherit !important;
  text-transform: capitalize;
}

#navigation #main-menu ul li ul li a:hover,
#navigation.navbar-fixed #main-menu ul li ul li a:hover,
#navigation.navbar-fixed #main-menu ul li ul li a:focus {
  color: #fff !important;
  background-color: #6168ff;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .navbar-brand {
    padding-top: 20px;
  }
}

/*
* ----------------------------------------------------------------------------------------
* 02.END BOOTSTRAP NAVIGATION OVERRIDES
* ----------------------------------------------------------------------------------------
*/
/*
* ----------------------------------------------------------------------------------------
* 03.START HOME DESIGN
* ----------------------------------------------------------------------------------------
*/

.home-slider {
  margin-top: 90px;
}

.single_home_slide,
.slide_overlay {
  min-height: 650px;
}

.single_home_slide {
  background-position: center center;
}

.slide_overlay {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
}

.single_home_slide .subtitle {
  color: #6168ff;
  font-size: 22px;
  margin-bottom: 20px;
  display: block;
}

.home-slider .btn-home-bg:hover,
.home-slider .btn-home-bg:focus {
  background-color: transparent !important;
  color: #fff !important;
  border-color: #ffff !important;
}

.tour_slider .swiper-button-prev::after,
.tour_slider .swiper-button-next::after {
  background-color: #fff;
  color: #6168ff;
}

.tour_slider .swiper-button-prev::after {
  content: "\f1e6";
  font-family: "Material Icons" !important;
}

.tour_slider .swiper-button-next::after {
  content: "\f1df";
  font-family: "Material Icons" !important;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.slider-info {
  position: absolute;
  top: 32%;
  width: 100%;
}

.btn-home-bg {
  background-color: #6168ff;
  border: 2px solid #6168ff !important;
  border-radius: 4px;
  color: #fff !important;
  font-size: 13px !important;
  padding: 13px 30px !important;
  transition: all 0.3s ease 0s !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.btn-home-bg:hover,
.btn-home-bg:focus {
  background: #333 !important;
  border-color: #333 !important;
  color: #fff !important;
  outline: 0;
}

.hero-text {
  padding-top: 160px;
}

.sl-subtitle {
  color: #6168ff;
  font-size: 30px;
}

.hero-text p {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 17px;
}

.hero-text h1 {
  color: #fff;
  font-size: 55px;
  margin-bottom: 50px;
  line-height: 1.4;
  text-transform: capitalize;
}

@media only screen and (max-width: 480px) {
  .hero-text p {
    color: #fff;
    margin: auto;
    width: 90%;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 820px) {}

.travel-search-form {
  color: #fff;
  padding: 50px;
  position: relative;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

.datepicker-wrapper .ti-calendar {
  font-size: 15px;
  top: 14px;
  right: 13px;
  color: #212529;
}

.travel-search-form .form-control {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
  height: 47px;
  line-height: 47px;
  padding: 10px 15px;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  background-color: #fff;
  color: #212529;
}

.travel-search-form .form-control:focus {
  border-color: #6168ff;
  box-shadow: inherit;
  border: 1px solid #6168ff;
}

.travel-search-form .btn-home-bg {
  padding: 9px 24px;
}

.search-select select {
  background-color: #fff;
  border: 1px solid #ddd;
  width: 100%;
  height: 47px;
  padding: 10px 15px;
  border-radius: 4px;
  color: #212529;
}

.gj-dropdown button {
  width: 100%;
}

.gj-dropdown-md [role="presenter"]:focus,
.gj-dropdown-md [role="presenter"] {
  border: 1px solid #ddd;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 4px;
  color: #212529;
}

.travel-search-form .btn-home-bg:hover,
.travel-search-form .btn-home-bg:focus {
  background-color: #fff;
  border-color: #fff;
  color: #212529;
}

ul.gj-list-md li [data-role="display"] {
  font-size: 14px;
}

.gj-dropdown-list-md {
  box-shadow: 7px 10px 40px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
}

.gj-dropdown-list-md li {
  padding: 8px 16px !important;
  font-size: 15px !important;
}

.gj-picker-md.datepicker {
  text-align: center;
}

.gj-picker-md.datepicker .ti-angle-left,
.gj-picker-md.datepicker .ti-angle-right {
  font-size: 14px;
}

.gj-picker-md table tr td.selected.gj-cursor-pointer div {
  color: #fff;
  background: #6168ff;
  border-radius: 50%;
}

/*
* ----------------------------------------------------------------------------------------
* 03.END HOME DESIGN
* ----------------------------------------------------------------------------------------
*/

/*
* ----------------------------------------------------------------------------------------
* 04.START SERVICE DESIGN
* ----------------------------------------------------------------------------------------
*/

.our_services {}

.service {
  background-color: #fff;
  margin-bottom: 40px;
  /* transition: all 0.4s ease 0s; */
  padding: 35px 35px 20px;
  border-radius: 5px;
  transition: 0.5s;
  background-color: #f4f9ff;
}

.service:hover {}

.service .icon {
  color: #6168ff;
  transition: all 0.3s ease 0s;
  margin-right: 20px;
  display: inline-block;

  float: left;
  line-height: 0;
}

.service .icon i {
  font-size: 50px;
}

.ser_text {
  overflow: hidden;
}

.service h4 {
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.service p {}

/*
* ----------------------------------------------------------------------------------------
* 04.END SERVICE DESIGN
* ----------------------------------------------------------------------------------------
*/

/*
 * ----------------------------------------------------------------------------------------
 * 05.START WHY CHOOSE US DESIGN
 * ----------------------------------------------------------------------------------------
*/

.single_choose {}

.single_choose .material-icons {
  font-size: 60px;
  color: #6168ff;
}

.single_choose h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.mrb40 {
  margin-bottom: 40px;
}

.choose_img {
  position: relative;
  margin-left: 30px;
  box-shadow: 18px 18px 26px 0 rgba(0, 1, 1, 0.2);
}

.choose_img .pbtn {
  background-color: #6168ff;
  color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}

.choose_img .pbtn:hover,
.choose_img .pbtn:focus {
  background-color: #212a42;
  color: #fff;
}

.choose_img .pbtn i {
  font-size: 45px;
  line-height: 80px;
}

/*
* ----------------------------------------------------------------------------------------
* 06.START COUNTER UP DESIGN
* ----------------------------------------------------------------------------------------
*/

.mt-60 {
  margin-top: 60px;
}

.single-counter {
  background-color: #6168ff;
  padding: 30px 20px;
  margin-bottom: 30px;
}

.counter-content {
  overflow: hidden;
}

.wy-para {
  margin-bottom: 40px;
}

.single-counter i {
  font-size: 40px;
  color: #fff;
  display: block;
  margin-bottom: 15px;
}

.counter-num {
  font-size: 40px;
  margin-bottom: 0;
  color: #fff;
}

.single-counter p {
  color: #fff;
}

.pr-0 {
  padding-right: 0px;
}

/*END COUNTER*/
/*
 * ----------------------------------------------------------------------------------------
 * 05.END WHY CHOOSE US DESIGN
 * ----------------------------------------------------------------------------------------
*/

/*
 * ----------------------------------------------------------------------------------------
 *  06.START SPECIAL PACKAGE DESIGN
 * ----------------------------------------------------------------------------------------
*/

.top_deals {
  padding-bottom: 100px;
}

#forfait {
  padding-top: 0;
}

.single_package {
  margin: 0 0px 30px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  border: 1px solid #ebe6de;
}

.pack_image {
  position: relative;
  transition: 0.5s;
  overflow: hidden;
}

.pack_price {
  color: #fff;
  font-size: 16px;
  position: absolute;
  top: -26px;
  right: 10px;
  background-color: #6168ff;
  display: inline-block;
  z-index: 9;
  text-align: center;
  border-radius: 30px;
  padding: 9px 18px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
  transition: 0.5s;
}

.single_package:hover .pack_price {
  background-color: #212a42;
  color: #fff;
}

.package-hover .tour-rating {
  color: #fec42d;
  margin-bottom: 9px;
}

.single_package a {
  color: #727272;
}

.single_package:hover a {
  color: #727272;
}

.single_package .package-hover {
  padding: 30px;
  position: relative;
}

.single_package img {
  width: 100%;
  position: relative;
}

.single_package h5 {
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: capitalize;
  font-weight: 700;
}

.single_package .rating {
  margin-bottom: 3px;
}

.single_package .pac-meta {}

.single_package .pac-meta i {
  color: #333;
}

.psingle-meta {
  margin-right: 5px;
}

#top-package:hover a,
#top-package a {
  color: #727272;
}

/*
 * ----------------------------------------------------------------------------------------
 * 06.END SPECIAL PACKAGE DESIGN
 * ----------------------------------------------------------------------------------------
*/

/*
* ----------------------------------------------------------------------------------------
* 05.START GALLERY DESIGN
* ----------------------------------------------------------------------------------------
*/

.single-gallery {
  position: relative;
  overflow: hidden;
}

.single-gallery:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(97, 104, 255, 0.8);
  transition: 0.5s;
  opacity: 0;
}

.single-gallery img {}

.gallery_enlarge_icon {
  opacity: 0;
}

.gallery_enlarge_icon {
  background-color: #fff;
  color: #333;
  width: 45px;
  height: 45px;
  line-height: 46px;
  position: absolute;
  right: 0px;
  bottom: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 19px;
  transition: 0.5s;
}

.gallery_enlarge_icon:hover,
.gallery_enlarge_icon:focus {
  background-color: #212a42;
  color: #fff;
}

.single-gallery h4 {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  letter-spacing: inherit;
  font-weight: 700;
  text-align: center;
}

.single-gallery:hover h4 {
  opacity: 1;
  top: 50%;
  left: 50%;
}

.portfolio-item {
  margin-bottom: 30px;
}

.gallery-btn {
  background-color: #6168ff;
  border: 1px solid #6168ff;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  padding: 13px 24px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
  font-weight: 400;
  letter-spacing: 1px;
}

.gallery-btn:hover,
.gallery-btn:focus {
  background-color: #211e3b;
  border-color: #211e3b;
  color: #fff;
}

.single-gallery:hover:before {
  opacity: 0.8;
}

.single-gallery:hover .gallery_enlarge_icon {
  opacity: 1;
  right: 20px;
  bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

/*
* ----------------------------------------------------------------------------------------
* 05.END GALLERY DESIGN
* ----------------------------------------------------------------------------------------
*/

/*
* ----------------------------------------------------------------------------------------
* 06.END COUNTER UP DESIGN
* ----------------------------------------------------------------------------------------
*/

/*
 * ----------------------------------------------------------------------------------------
 * 07.START TOP DEALS DESIGN
 * ----------------------------------------------------------------------------------------
 */

.padding_0 {
  padding: 0;
}

.top_deals {}

.single-deal {
  border: 1px solid #ebe6de;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  overflow: hidden;
  text-align: left;
}

.tour-rating {
  color: #fec42d;
}

.single-deal figure {
  position: relative;
  text-align: center;
  margin: 0;
}

.single-deal figure:before {}

.single-deal figure:hover:before {}

.single-deal figure img {
  position: relative;
  display: block;
  width: 100%;
}

.tour-rating {
  font-size: 13px;
}

.single-deal figcaption {
  overflow: hidden;
  padding: 25px 30px;
  text-align: left;
  background-color: transparent;
  transition: 0.2s;
  position: absolute;
  z-index: 11;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  text-align: left;
}

.single-deal h4 {
  margin-bottom: 7px;
  font-size: 20px;
  transition: 0.2s;
}

.single-deal h4,
.single-deal .tr-price {
  display: inline-block;
}

.single-deal .tr-price {
  font-size: 16px;
  font-weight: 600;
  float: right;
}

.single-deal figcaption p {
  margin-top: 0;
  margin-bottom: 0;
}

.single-deal i {
  font-size: 15px;
}

/*
 * ----------------------------------------------------------------------------------------
 * 07.END TOP DEALS DESIGN
 * ----------------------------------------------------------------------------------------
 */

/*
* ----------------------------------------------------------------------------------------
* 08.START DEALS & DISCOUNT DESIGN
* ----------------------------------------------------------------------------------------
*/
.tour_details .tour_slider {
  margin-bottom: 30px;
  height: 562px !important;
}

.related-deal {
  padding-bottom: 50px;
}

.tour_duration {
  font-weight: 600;
  color: #fff;
  margin-bottom: 11px;
  display: inline-block;
  background-color: #6168ff;
  padding: 6px 15px;
  border-radius: 30px;
}

/*END SINGLE TOUR DETAILS*/
/*
* ----------------------------------------------------------------------------------------
* 08.END DEALS & DISCOUNT DESIGN
* ----------------------------------------------------------------------------------------
*/

/*
 * ----------------------------------------------------------------------------------------
 * 10.START BLOG DESIGN
 * ----------------------------------------------------------------------------------------
 */

.blog_section {
  padding-top: 130px;
  padding-bottom: 100px;
}

.single_blog h4 {
  font-size: 28px;
}

img {
  max-width: 100%;
  display: block;
}

.single_blog {
  margin-bottom: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  border: 1px solid #ebe6de;
  overflow: hidden;
  position: relative;
}

.post_date {
  background: #6168ff;
  padding: 5px 20px;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 12px;
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 10px 10px 0 0;
  z-index: 99;
  border-radius: 0px;
  font-weight: 700;
}

.post_date a {}

.blog-text {
  padding: 30px 30px 30px;
}

.post-meta {
  margin-top: 5px;
  margin-bottom: 14px;
  font-size: 14px;
  overflow: hidden;
}

.post-meta span,
.post-meta span a {
  margin-right: 10px;
  color: #333;
  font-weight: 400;
}

.post-meta span i {
  color: #333;
  padding-right: 3px;
}

.blog-text {
  text-align: left;
}

.blog-text h4 {
  line-height: 30px;
  margin-bottom: 10px;
}

.blog-text p {
  margin-bottom: 35px;
}

.single_blog h4 a {
  font-size: 20px;
  color: #333;
}

.single_blog h4 a {
  transition: 0.5s;
}

.single_blog h4 a:hover {
  color: #6168ff;
}

.meta_cat a {
  display: inline-block;
  background-color: #6168ff;
  color: #fff;
  padding: 2px 18px;
  border-radius: 20px;
  margin-bottom: 17px;
  margin-right: 8px;
  font-size: 14px;
}

.entry-content p {
  margin-bottom: 15px;
}

.blog-details .post-meta {
  margin-bottom: 15px;
}

.blog-photo {}

.blog_image {
  position: relative;
}

.single_blog:hover .blog_image:before {
  opacity: 1;
}

.post-btn {
  font-weight: 700;
  color: #f05942;
  transition: 0.5s;
  text-decoration: underline !important;
}

.post-btn:hover,
.post-btn:focus {
  color: #f05942;
}

.comment-box {
  padding: 0 !important;
}

/*END BLOG SINGLE PAGE DESIGN*/

/*START BLOG SIDEBAR*/
.blog_sidebar_title {
  margin-bottom: 25px;
}

.search input {
  border: 1px solid #eee;
  border-radius: 30px;
  box-shadow: none;
  color: #333;
  height: 45px;
}

.search input:hover,
.search input:focus {
  border: 1px solid #6168ff;
  box-shadow: none;
}

.search,
.video_post,
.categories {
  margin-bottom: 30px;
}

.categories ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.categories ul li {}

.categories ul li a {
  color: #333;
  display: block;
  font-size: 12px;
  font-weight: 300;
  padding: 5px 0;
  text-transform: uppercase;
}

.categories ul li a:hover {
  color: #6168ff;
}

.categories ul li a i {
  margin-right: 10px;
}

.video_post iframe {
  width: 100%;
  height: 220px;
  border: medium none;
}

.tag a {
  margin-bottom: 10px;
  margin-right: 5px;
}

.btn-tag-bg {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #eee;
  border-radius: 30px;
  color: #333;
  display: inline-block;
  font-size: 12px;
  outline: medium none;
  padding: 15px 27px;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
}

.btn-tag-bg:hover,
.btn-tag-bg:focus {
  background: #6168ff;
  border: 1px solid #6168ff;
  color: #fff;
}

/*END BLOG SIDEBAR*/

/*
 * ----------------------------------------------------------------------------------------
 * 10.END BLOG DESIGN
 * ----------------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------------
 * 11.START TESTIMONIAL DESIGN
 * ----------------------------------------------------------------------------------------
*/
.testimonial .white-title h2 {
  color: #fff;
}

.testimonial {
  position: relative;
  padding-top: 130px;
}

.single-testimonial {
  color: #333;
  padding: 40px 28px;
  background-color: #f4f9ff;
  border-radius: 4px;
  position: relative;
  margin: 33px 0;
}

.testimonial .owl-item.active.center .single-testimonial:after {
  opacity: 0;
}

.testimonial-content {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  border-radius: 10px;
}

.testimonial .title {
  display: block;
  font-size: 19px;
  color: #333;
  text-transform: capitalize;
  margin: 2px 0 3px 0;
}

.testimonial-bottom {
  margin-top: 20px;
}

.testimonial .owl-item.active.center .single-testimonial {
  border-radius: 4px;
  background: #f4f9ff;
  color: #333;
}

.test-rating {
  color: #ffc000;
  font-size: 14px;
}

.test-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 19px;
  display: inline-block;
  border: inherit;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.07);
  background-color: #fff;
}

#testimonial-slider .test-img {
  position: relative;
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
}

#testimonial-slider .test-img:after {
  position: absolute;
  bottom: 13px;
  right: 0;
  width: 40px;
  height: 40px;
  content: "";
  background-image: url(../img/quote-img.png);
  background-color: #6168ff;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 11px;
}

#testimonial-slider.swiper {
  padding-bottom: 20px;
}

#testimonial-slider .swiper-button-next::after {
  content: "\ea94" !important;
  font-family: "IcoFont" !important;
  /* transform: rotate(-90deg); */
  font-size: 20px !important;
}

#testimonial-slider .swiper-button-prev::after {
  content: "\ea94" !important;
  font-family: "IcoFont" !important;
  transform: rotate(180deg);
  font-size: 20px !important;
}

#testimonial-slider .swiper-button-next {
  position: absolute;
  top: auto !important;
  bottom: -10px !important;
  right: 45% !important;
  transform: translateX(-45%);
  color: #00102a;
  /* width: 100%; */
  margin: 0;
}

#testimonial-slider .swiper-button-prev {
  position: absolute;
  top: auto !important;
  bottom: -10px !important;
  left: 50% !important;
  transform: translateX(-50%);
  color: #00102a;
  /* width: 100%; */
  margin: 0;
}

/*
 * ----------------------------------------------------------------------------------------
 * 11.END TESTIMONIAL DESIGN
 * ----------------------------------------------------------------------------------------
*/
/*
* ----------------------------------------------------------------------------------------
* 12.START COMPANY PARTNER LOGO
* ----------------------------------------------------------------------------------------
*/
.partner-logo {
  position: relative;
  padding: 80px 30px;
  background-color: #6168ff;
}

.partner-logo h2 {
  margin-top: 25px;
}

.partner {
  text-align: center;

  border-radius: 0px;
}

.partner.owl-theme .owl-controls {
  display: none !important;
}

.partner-logo .owl-carousel .owl-item img {
  display: inline-block;
  max-width: 100%;
  border-radius: 4px;
}

/*
* ----------------------------------------------------------------------------------------
*  12.END COMPANY PARTNER LOGO
* ----------------------------------------------------------------------------------------
*/

/*
* ----------------------------------------------------------------------------------------
* 13.START CONTACT DESIGN
* ----------------------------------------------------------------------------------------
*/

.contact_area {
  padding-bottom: 130px;
}

.mt-30 {
  margin-top: 30px;
}

.white-title span,
.white-title h2,
.white-title {
  color: #fff;
}

.contact_area {
  position: relative;
  background-size: cover;
  z-index: 99;
}

.con-overlay {}

#contact-form {
  background-color: #f4f9ff;
  padding: 55px 40px;
  border-radius: 4px;
}

.contact_address {
  margin-bottom: 30px;
  padding-left: 30px;
}

.single-contact-info {
  overflow: hidden;
  padding: 48px 40px;
  position: relative;
  background-color: #f4f9ff;
  border-radius: 4px;
  margin-bottom: 30px;
  margin-right: 10px;
  text-align: center;
}

.single-contact-info i {
  color: #fff;
  font-size: 25px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background-color: #6168ff;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 15px;
}

.single-contact-info p {
  margin-bottom: 0;
}

.contact .form-control {
  padding: 13px 23px;
  border-radius: 4px;
  background-color: #fff;
  border-color: #fff;
  border-width: 1px;
  margin-bottom: 30px;
}

.contact .form-control:focus {
  border-color: #6168ff;
  box-shadow: none;
  outline: 0 none;
}

.contact textarea {
  background: #fff none repeat scroll 0 0;
  border-radius: 0px;
  box-shadow: none;
  padding: 5px 15px;
  height: 200px;
}

.contact textarea:focus {
  box-shadow: none;
  outline: 0 none;
}

.success {
  background: #fff none repeat scroll 0 0;
  color: #42c0d9;
  font-weight: 700;
  padding: 20px;
  text-align: center;
}

.btn-contact-bg,
#submitButton.btn-contact-bg {
  background-color: #6168ff;
  border: 2px solid #6168ff;
  color: #fff;
  font-size: 13px;
  padding: 12px 30px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  font-weight: 700;
  border-radius: 4px;
  display: inline-block;
}

#submitButton:hover,
#submitButton:focus,
.btn-contact-bg:hover,
.btn-contact-bg:focus {
  background: #211e3b;
  border: 2px solid #211e3b !important;
  color: #fff;
  box-shadow: inherit;
}

.subscribe-form button {
  margin-top: 10px;
}

.subscribe-form button:hover,
.subscribe-form button:focus {
  background-color: #fff;
  border-color: #fff;
  color: #333;
}

/*
* ----------------------------------------------------------------------------------------
* 14.START FOOTER DESIGN
* ----------------------------------------------------------------------------------------
*/

.footer {
  padding: 250px 0 40px;
  background-color: #00102a;
  color: #fff;
  clip-path: polygon(0 250px, 100% 0, 100% 100%, 0% 100%);
}

.footer-top {
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(247, 243, 243, 0.3);
  padding-top: 30px;
  padding-bottom: 30px;
}

.cont_info i {
  margin-right: 20px;
  float: left;
}

.cont_info span {
  display: block;
  overflow: hidden;
}

.footer ul li a {
  font-weight: 400;
  color: #fff;
}

.copyright_text,
.copyright_text a:hover,
.copyright_text a:focus {
  color: #fff;
}

.copyright_text a {
  font-weight: 700;
}

.foot-logo {
  margin-bottom: 25px;
}

.foot-logo img {
  width: 120px;
}

.footer .fot-title {
  position: relative;
  margin-bottom: 26px;
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
}

.footer ul {
  list-style-type: none;
}

.footer ul li {
  line-height: 32px;
}

.footer .copyright {
  padding-top: 30px;
}

.footer .copyright p {
  margin-bottom: 0;
  font-weight: 600;
}

.footer-bottom p {
  margin-bottom: 0;
  font-size: 15px;
}

.footer ul {
  list-style-type: none;
}

.footer ul li {
  line-height: 32px;
  position: relative;
}

.footer ul li a {
  font-weight: 400;
}

.footer a {
  transition: 0.5s;
}

.footer a:hover,
.footer a:focus {
  color: #6168ff;
}

.foot_social_link {
  margin-top: 19px;
  display: block;
}

.foot_social_link li {
  display: inline-block;
  margin: 0 7px;
}

.footer .foot_social_link li a {
  font-size: 18px;
  color: #6168ff;
  transition: 0.5s;
}

.footer .foot_social_link li a:hover,
.footer .foot_social_link li a:focus {
  color: #fff;
}

.fot-about-us {
  padding-right: 20px;
}

.copyright_text a {
  color: #fff;
}

/*
* ----------------------------------------------------------------------------------------
* 14.END FOOTER DESIGN
* ----------------------------------------------------------------------------------------
*/

/*
* ----------------------------------------------------------------------------------------
* 15.Tour Details DESIGN
* ----------------------------------------------------------------------------------------
*/

.page_banner {
  position: relative;
  margin-top: 90px;
}

.banner_overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 113px 0 90px;
}

.banner_overlay .section-blog-title {
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
}

.single_tour_details {
  padding-right: 20px;
}

.tour_details {
  padding-bottom: 0;
}

.book_now {
  background-color: #fff;
  padding: 30px 30px 35px;
  border: 1px solid #f1f1f1;
}

.book_now .form-control {
  margin-bottom: 25px;
  background-color: #f4f9ff;
  border-color: #f4f9ff;
  padding: 8px 15px;
  border-radius: 0;
}

.book_now h4 {
  margin-bottom: 25px;
  font-size: 20px;
}

.book_now .btn-home-bg {
  padding: 12px 15px;
  display: block;
  width: 100%;
  font-family: "Nunito", sans-serif;
}

.book_now .form-control::placeholder {}

.book_now .form-control:focus {
  box-shadow: inherit;
  border-color: #6168ff;
}

.book_now .book_now_btn {
  background: #fff;
  color: #333;
  border: 1px solid #fff;
  padding: 7px 24px;
  display: inline-block;
  font-weight: 600;
  width: 100%;
  transition: 0.5s;
}

.book_now .book_now_btn:hover,
.book_now .book_now_btn:focus {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.tour-map {
  margin-top: 80px;
}

.tour-map iframe {
  border: inherit;
  width: 100%;
}

.tour_title {
  font-size: 22px;
  margin-bottom: 25px;
}

.tour_slider_img {}

.tour_slider .swiper-button-prev,
.tour_slider .swiper-button-next {
  color: #6168ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  background-color: #fff;
  width: 50px !important;
  height: 50px !important;
  line-height: 47px;
  border-radius: 50%;
  opacity: 1;
  border: 1px solid #fff;
}

/*
* ----------------------------------------------------------------------------------------
* 15.START BLOG DESIGN
* ----------------------------------------------------------------------------------------
*/

#blog-section {
  padding: 80px 0;
}

#blog-section .single_blog h4 a {
  font-size: 1.5rem;
}

.blog-details .blog-text {
  position: relative;
  bottom: 0;
  left: 0;
  padding: 30px;
  color: #666666;
  z-index: 9;
}

.blog-details .blog-text .post-meta span,
.blog-details .blog-text .post-meta span a,
.blog-details .blog-text .post-meta a {
  color: #666666;
}

.blog-section.blog-details .single_blog:after {
  position: relative;
  bottom: 0px;
  left: 0px;
}

.blog-text.text-left,
.entry-content {
  text-align: left;
}

.blog-text.text-left {}

.post_description {
  margin-top: 12px;
}

.content-area .post_description {
  margin-top: 18px;
  margin-bottom: 18px;
  overflow: hidden;
}

.pagination {
  display: block;
}

.pagination-nav {
  padding-top: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.pagination-nav a {
  background-color: #f4f9ff;
  color: #333;
  font-weight: 500;
  font-size: 15px;
  border-radius: 4px;
  text-align: center;
  margin: 0 6px;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
}

.pagination-nav a:hover,
.pagination-nav a:focus,
.pagination-nav a.active {
  background-color: #6168ff;
  border-color: #6168ff;
  color: #fff;
}

.post_tags {
  font-weight: 700;
  margin-top: 30px;
}

.post_tags a {
  color: #fff !important;
  transition: 0.5s;
  background-color: #6168ff;
  padding: 2px 15px;
  margin-right: 5px;
  display: inline-block;
  font-weight: 500;
  border-radius: 4px;
}

.post_tags a:hover,
.post_tags a:focus {
  color: #fff !important;
  background-color: #333;
}

.post_tags a:hover,
.post_tags a:focus {
  color: #6168ff;
}

#sidebar-section {}

.widget {
  margin-bottom: 30px;
  padding-bottom: 10px;
  background-color: #f4f9ff;
  overflow: hidden;
}

.widget_title {
  font-size: 24px;
  text-transform: capitalize;
  color: #333;
  margin: 30px 30px 6px 30px;
  padding-bottom: 14px;
  display: block;
  position: relative;
}

.widget.widget_categories {
  padding-bottom: 0px;
}

.widget ul {}

.widget.widget_search {
  padding: 30px;
}

.widget.widget_search .widget_title {
  margin-bottom: 20px;
  margin-left: 0;
  margin-top: 0;
}

.widget:last-child {
  margin-bottom: 0;
}

.widget ul>li {
  list-style-type: none;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  padding-left: 30px;
  padding-right: 30px;
  transition: 0.5s;
}

.widget ul>li>ul>li {
  padding-left: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  border-bottom: inherit;
}

.widget li a {
  font-weight: 600;
}

.widget.widget_categories ul>li:hover {
  background-color: #6168ff;
  cursor: pointer;
  color: #fff;
}

.widget.widget_categories li a {
  font-size: 16px;
  color: #727272;
}

.widget.widget_categories li:hover a {
  color: #fff;
}

.widget li a:hover,
.widget li a:focus {
  color: #6168ff;
}

.widget li:last-child {
  border-bottom: inherit;
}

.widget.widget_text .textwidget,
.widget .calendar_wrap,
.widget.widget_categories form {
  padding-left: 30px;
  padding-right: 30px;
}

.widget.widget_archive select,
.widget.widget_categories select {
  margin-top: 20px;
  margin-bottom: 30px;
}

.widget.widget_archive select {
  margin-left: 30px;
  width: 83%;
}

.widget.widget_text .widget_title {
  margin-bottom: 15px;
}

.widget.widget_rss .rsswidget {
  display: inline-block;
}

#reply-title {
  font-size: 24px;
  color: #333;
}

.widget_categories li a {
  text-transform: capitalize;
  font-weight: 700;
}

.widget.widget_tag_cloud {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.widget.widget_tag_cloud .widget_title {
  margin-bottom: 25px;
  margin-left: 0;
  margin-right: 0;
}

.widget.widget_tag_cloud a {
  color: #727272;
}

.search_form {}

.form-control {
  height: auto;
  line-height: 45px;
}

.form-control:focus {
  border-color: #6168ff;
}

.search_form form {
  position: relative;
}

.search_form .form-control::-webkit-input-placeholder {
  color: #666666;
  opacity: 1;
}

.search_form .form-control::-moz-placeholder {
  color: #666666;
  opacity: 1;
}

.search_form .form-control:-ms-input-placeholder {
  color: #666666;
  opacity: 1;
}

.search_form .form-control {
  height: 50px;
  line-height: 50px;
  padding: 10px 15px;
  background-color: #fff;
  color: #666666;
  border-radius: 4px;
  font-size: 14px;
}

.search_form .form-control:focus {
  box-shadow: inherit;
  border-color: #6168ff;
}

.search_form button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #6168ff;
  color: #fff;
  border: 1px solid transparent;
  height: 50px;
  padding: 0 25px;
  line-height: 50px;
  border-radius: 4px;
}

.widget_categories i {
  padding-right: 5px;
  color: #6168ff;
}

.widget_categories .badge {
  font-size: 13px;
}

.widget_categories li:hover i,
.widget_categories li:hover span {
  color: #fff;
}

.widget_categories li span {
  color: #727272;
}

.ward_recent_post a {
  color: #727272;
}

.ward_recent_post h3 {
  font-size: 17px;
}

.widget_tag_cloud a {
  display: inline-block;
  padding: 2px 13px;
  margin-right: 8px;
  margin-bottom: 12px;
  font-size: 13px;
  transition: 0.5s;
  background-color: #fff;
  font-weight: 500;
  border-radius: 4px;
}

.widget_tag_cloud a:hover,
.widget_tag_cloud a:focus {
  color: #fff;
  background-color: #6168ff;
  border-color: #6168ff;
}

.widget_instagram ul {
  padding: 10px 20px 10px 30px;
}

.widget_instagram li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  width: 29%;
  float: left;
  position: relative;
  padding: 0 !important;
  border-bottom: inherit;
  transition: 0.5s;
}

.widget_instagram li a {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.5s;
}

.widget_instagram li:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: 0.5s;
}

.widget_instagram li a:after {
  position: absolute;
  content: "\e73d";
  font-family: "themify";
  width: 100%;
  height: 100%;
  top: 37%;
  left: 0;
  color: #fff;
  text-align: center;
  display: inline;
  z-index: 11;
  opacity: 0;
  transition: 0.5s;
}

.widget_instagram li a:hover::after,
.widget_instagram li:hover::before {
  opacity: 1;
}

.widget_instagram img {
  width: 100%;
}

.turista_recent_post h3 {
  font-size: 17px;
}

/* Comment Style */
.comment-area {
  padding-top: 30px;
  margin-bottom: 20px;
  overflow: hidden;
}

.comment-area li {
  list-style-type: none;
  margin-bottom: 25px;
}

.comment-area li ul {
  padding-left: 30px;
  margin-top: 25px;
}

.comment-title,
.comment-form h3 {
  font-size: 24px;
  margin-bottom: 40px;
}

.author_pic {
  float: left;
  margin-right: 25px;
}

.author_pic img {
  border-radius: 50%;
  width: 80px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
}

.comment_text {
  overflow: hidden;
  margin-bottom: 45px;
}

.com-title {
  font-size: 17px;
}

.comment_text .date {
  font-weight: 400;
}

.comment_text .date .com_reply {
  float: right;
  font-size: 13px;
}

.comment-form {
  padding-top: 10px;
}

.comment-form .form-control {
  margin-bottom: 20px;
}

.comment-form textarea {
  height: 150px;
}

.comment-form .form-control:focus {
  box-shadow: inherit;
}

.send-message-info i {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border: 1px solid #6168ff;
  color: #fff;
  background-color: #6168ff;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 30px;
}

.send-message-info h4 {
  margin-bottom: 40px;
}

/* Signle Service */

.single_service_details h2 {
  margin-bottom: 25px;
}

/* Responsive Design */

@media only screen and (max-width: 1366px) {
  .single-counter {
    background-color: #6168ff;
    padding: 30px 14px;
  }

  .blog-text h4 {
    line-height: 32px;
  }

  .single_blog h4 a {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1199px) {

  .travel-search-form .col-xl-3.col-lg-6,
  .single-counter,
  .footer-widget {
    margin-bottom: 30px;
  }

  .why_choose .section-title {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1024px) {
  #main-menu ul li {
    margin-left: 30px;
  }

  #testimonial-slider .swiper-button-prev {
    left: 45% !important;
  }
}

@media only screen and (max-width: 991px) {
  #main-menu ul li {
    margin-left: 9px;
  }

  #main-menu ul li a {
    font-size: 15px;
  }

  #navigation {
    padding: 10px 0 20px;
  }

  .choose_img {
    margin-left: 0;
    margin-top: 40px;
  }

  .contact_address {
    padding-left: 0;
    margin-top: 40px;
  }

  .single-counter,
  .footer-widget,
  .single-contact-info {
    margin-bottom: 30px;
  }

  #top-package .swiper-button-next,
  #top-packager .swiper-button-prev {
    display: none;
  }

  .pagination-nav {
    margin-bottom: 60px;
  }

  .banner_overlay .section-blog-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  #main-menu {
    display: none;
  }

  #mobile_menu {
    display: block;
    width: 100%;
  }

  .slicknav_nav {
    background-color: #211e3b;
  }

  #navigation #mobile_menu li a {
    color: #fff;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
  }

  #navigation #mobile_menu li li a {
    color: #fff;
  }

  #navigation #mobile_menu li i {
    display: none;
  }

  #navigation ul li ul,
  #navigation ul {
    transition: 0;
  }

  #navigation.navbar-fixed #mobile_menu li a {
    color: #fff;
  }

  .slicknav_nav a {
    padding: 1px 0px;
    transition: 0.5s;
  }

  #navigation #mobile_menu li a:hover,
  #navigation #mobile_menu li a:focus {
    color: #6168ff;
  }

  .slicknav_btn {
    top: 24px;
  }

  .why_choose img {
    margin-bottom: 30px;
  }

  .single_tour_details {
    padding-right: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .slicknav_btn {
    margin: 0;
    text-decoration: none !important;
    position: absolute !important;
    top: 35px !important;
    right: 17px !important;
  }

  .home-slider .swiper-button-next,
  .home-slider .swiper-button-prev {
    display: none !important;
  }

  .hero-text {
    padding-top: 172px;
  }

  .hero-text h1 {
    font-size: 40px;
    margin-bottom: 25px;
    line-height: 56px;
  }

  #testimonial-slider .swiper-button-prev,
  #testimonial-slider .swiper-button-next {
    display: none !important;
  }

  .single_blog.big-post .blog-text {
    left: 20px;
  }

  .right-blog .blog_image {
    width: 100%;
    float: inherit;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .right-blog .blog-text {
    overflow: initial;
    display: block;
  }
}

/* swiper style for home slider */
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 14px !important;
  font-weight: bold;
}

#home .swiper .swiper-button-prev {
  right: 30px !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  background: #fff;
  left: auto !important;
  top: 45% !important;
  transition: all 0.5s ease;
}

#home .swiper-button-next {
  right: 30px !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  background: #fff;
  left: auto !important;
  top: 54% !important;
  transition: all 0.5s ease;
}

#home .swiper-button-prev::after {
  content: "\e5d8" !important;
  font-family: "Material Icons" !important;
}

#home .swiper-button-next::after {
  content: "\e5db" !important;
  font-family: "Material Icons" !important;
}

#home .swiper-button-prev:hover,
#home .swiper-button-next:hover {
  background-color: #42c0d9;
  color: #fff !important;
}

/* swiper style for tour details */

#top-package .swiper-button-prev {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  background: #fff;
  transition: all 0.5s ease;
}

#top-package .swiper-button-next {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  background: #fff;
  transition: all 0.5s ease;
}