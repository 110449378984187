/* Styles Switcher */
:root {
  --color: #6168ff;
}

#style-switcher {
  background: #fff none repeat scroll 0 0;
  border-radius: 0 0 3px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.06);
  left: 0;
  position: fixed;
  top: 168px;
  width: 220px;
  z-index: 9999;
  padding: 20px;
}
#style-switcher div {
  padding: 0px 10px;
}
#style-switcher h2 {
  color: #333;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 0;
  margin-top: 0;
  padding: 10px 0;
  text-align: left;
  text-transform: capitalize;
  margin-left: 20px;
  cursor: pointer;
}
#style-switcher h2 a {
  background: #6168FF none repeat scroll 0 0;
  display: block;
  height: 50px;
  position: absolute;
  right: -50px;
  top: 0;
  width: 50px;
  border-radius: 0 4px 4px 0;
}
#style-switcher h2 a i {
  color: #fff;
  font-size: 26px;
  left: 12px;
  position: absolute;
  top: 12px;
}
.colors {
  padding-left: 7px;
}
.colors {
  list-style: none;
  margin: 0px 0px 10px 0px;
  overflow: hidden;
}
.colors li {
  float: left;
}
.colors li a {
  border-radius: 5px;
  cursor: pointer;
  display: block;
  height: 35px;
  margin: 6px;
  width: 35px;
}
.layout-style select {
  width: 100%;
  padding: 5px;
  border: none;
  margin: 0 0 0 -5px;
  color: #fff;
  cursor: pointer;
}
#reset {
  margin: 0 0 15px 2px;
}
#reset a {
  color: #fff;
  font-size: 12px;
}
.color_item {
  background-color: var(--color);
  cursor: pointer;
}
.colors li a.active {
  box-shadow: 0 0 0px 2px #fff;
}
.icon-2x {
  color: #fff;
  font-size: 22px;
  line-height: 30px;
}

@media only screen and (max-width: 768px) {
  #style-switcher {
    display: none;
  }
}
